.mint-form {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 600px;

  .mint-btn-group {
    display: flex;
    gap: 1rem;
  }

  @media (max-width: 600px) {
    width: 90%;
  }
}

.mint-counter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 250px;

  .mint-operator {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 2px solid white;
    width: 50px;
    height: 50px;
    font-size: 32px;
    line-height: 32px;
    cursor: pointer;
  }

  input:disabled {
    width: 100px;
    cursor: pointer;
    color: white;
    text-align: center;
    font-size: 3rem;
    height: auto;
    background: transparent;
    outline: 0;
    border: none;
  }
}

.circle-timer-container {
  background-color: #55555555;
  border-radius: 50px;
  padding: 0 20px 20px 20px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  text-align: center;
  padding-top: 20px;

  .time-wrapper {
    display: flex;
    font-size: 16px;
    .time {
      font-size: 32px;
      @media (max-width: 600px) {
        font-size: 20px;
      }
    }
  }
}

.Toastify__toast-body {
  font-size: 16px;
  display: flex;
  width: 100px;
  padding: 0;
  flex-wrap: wrap;
}
